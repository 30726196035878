@import url(https://fonts.googleapis.com/css2?family=Play:wght@400;700&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


:root {
  --color-primary: #eb5e7c;
  --color-primary-trans: #eb5e7c25;
  --color-primary-dark: #b3475e;
  --color-white: #fff;
  --color-white-trans: rgba(255, 255, 255, 0.95);
  --color-lightgrey: #ECEEF1;
  --color-mediumlightgrey: #cacdd1;
  --color-mediumgrey: #70747B;
  --color-darkgrey: #1F2227;
  --color-green: #36BC8C;
  --color-red: #ff5454;
  --color-blue: #1f97dd;
}

body {
  background-color: #ECEEF1;
  background-color: var(--color-lightgrey);
  font-family: 'Play', sans-serif;
  color: #1F2227;
  color: var(--color-darkgrey);
  line-height: 130%;
  font-size: 15px;
}

a {
  color: #eb5e7c;
  color: var(--color-primary);
  text-decoration: none !important;
}
a:hover {
  color: #b3475e;
  color: var(--color-primary-dark);
}
img {  
  display: block;
}

#loader {
  position: absolute;
  display: -webkit-flex;
  display: flex;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  background: rgba(255,255,255,.5);
  z-index: 99;
}

#wrapper {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  height: 100vh;
}

.container {
  width: 100%;
  max-width: 1280px;
  margin: 0 auto;
  padding: 0 30px;
  box-sizing: border-box;
}
.box {
  background-color: #fff;
  background-color: var(--color-white);
  border-radius: 4px;
  padding: 20px;
  box-sizing: border-box;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.08);
}
.green { color: #36BC8C !important; color: var(--color-green) !important; }
.grey { color: #70747B !important; color: var(--color-mediumgrey) !important; }
.lightgrey { color: #cacdd1 !important; color: var(--color-mediumlightgrey) !important; }
.blue { color: #1f97dd !important; color: var(--color-blue) !important; }


#loginContent {
  display: -webkit-flex;
  display: flex;
  height: 100vh;
  -webkit-align-items: center;
          align-items: center;
}
#loginContent > .container {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  max-width: 460px;
  margin-bottom: 100px;
}

#sidebar {
  position: relative;
  -webkit-flex: 1 1;
          flex: 1 1;
  -webkit-flex-basis: 320px;
          flex-basis: 320px;
  -webkit-flex-grow: 0;
          flex-grow: 0;
  background-color: #fff;
  background-color: var(--color-white);
}
#logo {
  max-width: 180px;
  margin: 30px;
}

#menu .menuItem {
  position: relative;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-align-items: center;
          align-items: center;
  height: 70px;
  text-decoration: none;
  border-bottom: 1px solid #ECEEF1;
  border-bottom: 1px solid var(--color-lightgrey);
}
#menu .menuItem:first-child {
  border-top: 1px solid #ECEEF1;
  border-top: 1px solid var(--color-lightgrey);
}
#menu .menuIcon {
  width: 80px;
}
#menu .menuIcon svg {
  width: 20px;
  height: 20px;
  margin-left: 30px;
  -webkit-filter: grayscale(1);
          filter: grayscale(1);
  transition: all .15s ease-in-out;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none;
      user-select: none;
}
#menu .menuTitle {
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  color: #1F2227;
  color: var(--color-darkgrey);
  transition: color .1s ease;
}
#menu .menuItem:after {
  position: absolute;
  right: 30px;
  content: '';
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 5px 0 5px 8px;
  border-color: transparent transparent transparent #cacdd1;
  border-color: transparent transparent transparent var(--color-mediumlightgrey);
  transition: border-color .1s ease;
}
#menu .menuItem:hover .menuIcon svg {
  margin-left: 45px;
}
#menu .menuItem:hover, #menu .menuItem.active {
  background-color: #f4f6f9;
}
#menu .menuItem.active .menuIcon svg {
  margin-left: 45px;
  -webkit-filter: grayscale(0);
          filter: grayscale(0);
}
#menu .menuItem.active .menuTitle {
  color: #eb5e7c;
  color: var(--color-primary);
}
#menu .menuItem.active:after {
  border-color: transparent transparent transparent #eb5e7c;
  border-color: transparent transparent transparent var(--color-primary);
}

#menu .logoutButton {  
  position: absolute;
  bottom: 0;
  width: 100%;
  cursor: pointer;
  border-top: 1px solid #ECEEF1;
  border-top: 1px solid var(--color-lightgrey);
}

.smallIconFix {  
  width: 23px !important;
  height: 23px !important;
  -webkit-transform: translateX(-2px);
          transform: translateX(-2px);
}

#page {
  -webkit-flex: 1 1;
          flex: 1 1;
  height: 100vh;
  overflow-y: auto;
}

#pageHeader {
  background-color: #70747B;
  background-color: var(--color-mediumgrey);
  height: 70px;
}
#pageHeader > .container {  
  display: -webkit-flex;  
  display: flex;
  height: 100%;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}
.pageTitle {
  color: #fff;
  color: var(--color-white);
  font-size: 22px;
  text-transform: uppercase;
  font-weight: 700;
}
.pageTime {
  color: #fff;
  color: var(--color-white);
}

#pageActions {
  background-color: #eb5e7c;
  background-color: var(--color-primary);
  padding: 30px 0 70px;
}
#pageActions > .container {  
  display: -webkit-flex;  
  display: flex;
  height: 100%;
  -webkit-align-items: center;
          align-items: center;
  /* justify-content: space-between; */
}

#pageContent {
  margin-top: -40px;
  margin-bottom: 60px;
}


.window {
  border: 1px solid #ECEEF1;
  border: 1px solid var(--color-lightgrey);
  border-radius: 4px;
}
.row .window:not(:last-child){
  margin-bottom: 20px;
}
.windowHead {
  background-color: #ECEEF1;
  background-color: var(--color-lightgrey);
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
  height: 47px;
  padding-left: 20px;
}
.windowTitle {
  color: #1F2227;
  color: var(--color-darkgrey);
  font-weight: 700;
  font-size: 14px;
  text-transform: uppercase;
}
.windowContent {
  padding: 20px;
}
.windowActions {
  -webkit-flex-shrink: 0;
          flex-shrink: 0;
}
.windowActions .btn {
  margin-right: 4px;
}
.windowActions > img {  
  display: inline-block;
  width: 28px;
  height: 28px;
  margin-right: 15px;
  margin-top: 2px;
  cursor: pointer;
}


.gridButtons button {
  margin-left: 15px;
}
.gridButtons button {
  display: -webkit-flex;
  display: flex;
  width: 50px;
  height: 47px;
  padding: 0 !important;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}
.gridButtons button img {
  display: inline-block;
}
.gridButtons button.active {
  background-color: #eb5e7c !important;
  background-color: var(--color-primary) !important;
}
.gridButtons button.active img {
  -webkit-filter: invert(1);
          filter: invert(1);
}


/* .languageSelector {
  margin-left: 20px;
} */
.languageSelector .dropdown-toggle {
  border-radius: 3px !important;
  padding-left: 1rem !important;
  padding-right: 1rem !important;
}
.languageSelector .dropdown-toggle:after {  
  margin-left: 0 !important;
  vertical-align: .1em !important;
}
.languageSelector .dropdown-item {
  padding: .25rem 1rem !important;
}
.languageSelector .dropdown-item:active {
  background-color: #eb5e7c;
  background-color: var(--color-primary);
}
.languageSelector img {
  display: inline-block;
  width: 19px;
  height: 19px;
  margin-right: 10px;
  vertical-align: bottom;
}



.statusCircle {
  width: 18px;
  height: 18px;
  border-radius: 100%;
  -webkit-flex-shrink: 0;
          flex-shrink: 0;
  -webkit-flex-grow: 0;
          flex-grow: 0;
}
.gridItem .statusCircle {
  margin-left: auto;
  margin-top: 2px;
}
.statusCircle.active { background-color: #36BC8C; background-color: var(--color-green); }
.statusCircle.inactive { background-color: #ff5454; background-color: var(--color-red); }

.gridItem {
  display: block;
  margin-bottom: 20px;
  transition: background-color .1s ease-in-out;
}
.gridItem:hover {
  background-color: #f9f9f9;
}
.gridImage {
  height: 170px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  margin-bottom: 15px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}
.gridImage img {
  max-width: 100%;
  max-height: 100%;
}
.gridTitle {
  color: #1F2227;
  color: var(--color-darkgrey);
  font-weight: 700;
  font-size: 18px;
}
.gridSubtitle {
  color: #eb5e7c;
  color: var(--color-primary);
  font-weight: 700;
  margin-top: 8px;
}
.gridMeta {
  color: #1F2227;
  color: var(--color-darkgrey);
  margin-top: 8px;
}


.table {  
  margin: 0;
  padding: 0 5px;
}
.table-head {
  border-bottom: 1px solid #ECEEF1;
  border-bottom: 1px solid var(--color-lightgrey);
  padding-bottom: 15px;
  margin-bottom: 15px;
}
.table-head span {
  color: #eb5e7c;
  color: var(--color-primary);
  cursor: pointer;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.sortIcon {
  display: inline-block;
  padding: 2px 4px;
  margin-left: 4px;
  vertical-align: text-bottom;
}
.sortIcon::before {
  display: block;
  content: '';
  width: 0; 
  height: 0; 
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;  
  border-bottom: 5px solid #cacdd1;  
  border-bottom: 5px solid var(--color-mediumlightgrey);
  margin-bottom: 2px;
}
.sortIcon::after {
  display: block;
  content: '';
  width: 0; 
  height: 0; 
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid #cacdd1;
  border-top: 5px solid var(--color-mediumlightgrey);
}
.sortIcon.sortUp::before {
  border-bottom: 5px solid #eb5e7c;
  border-bottom: 5px solid var(--color-primary);
}
.sortIcon.sortDown::after {
  border-top: 5px solid #eb5e7c;
  border-top: 5px solid var(--color-primary);
}

.listItem > .row {
  border-radius: 24px;
  transition: background-color .1s ease-in-out;
}
.listItem:hover > .row {
  background-color: #f9f9f9;
}
.listItem > .row > .col {  
  display: -webkit-flex;  
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  height: 48px;
  color: #1F2227;
  color: var(--color-darkgrey);
}



.itemList > *:not(:last-child) {
  margin-bottom: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid #ECEEF1;
  border-bottom: 1px solid var(--color-lightgrey);
}

.inputList > *:not(:last-child) {
  margin-bottom: 10px;
}

.folderList {  
  margin: 0;
}
.folder {  
  cursor: pointer;
  padding: 8px 0;
  border-radius: 3px;
  -webkit-align-items: center;
          align-items: center;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none;
      user-select: none;
}
.folder:active {
  background-color: #ECEEF1;
  background-color: var(--color-lightgrey);
}
.folder:hover .folderTitle {
  color: #eb5e7c;
  color: var(--color-primary);
}
.folderIcon {  
  display: -webkit-flex;  
  display: flex;
  -webkit-flex-basis: 0;
          flex-basis: 0;
  -webkit-flex-grow: 0;
          flex-grow: 0;
  -webkit-align-items: center;
          align-items: center;
}
.folderIcon img {
  width: 24px;
  height: 24px;
  margin-top: -2px;
  margin-bottom: 2px;
}
.folderTitle {
  font-size: 17px;
}


.folderBack {  
  cursor: pointer;
  padding: 8px 10px;
  margin: -0 -10px;
  font-weight: 700;
  border-radius: 3px;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none;
      user-select: none;
}
.folderBack:hover {
  color: #eb5e7c;
  color: var(--color-primary);  
}
.folderBack:active {
  background-color: #ECEEF1;
  background-color: var(--color-lightgrey);
}

.fileList {  
  margin: 0;
}
.file {
  cursor: default;
  padding: 8px 0;
}
.fileIcon {  
  display: -webkit-flex;  
  display: flex;
  -webkit-flex-basis: 0;
          flex-basis: 0;
  -webkit-flex-grow: 0;
          flex-grow: 0;
  -webkit-align-items: center;
          align-items: center;
}
.fileIcon img {
  width: 30px;
  height: 30px;
}
.fileTitle {
  font-size: 16px;
}
.file:hover .fileTitle {
  color: #eb5e7c;
  color: var(--color-primary);
}
.fileMeta {
  font-size: 14px;
  color: #70747B;
  color: var(--color-mediumgrey);
}

.viewButton {
  display: block;
  padding: 10px;
  border: 1px solid #cacdd1;
  border: 1px solid var(--color-mediumlightgrey);
  border-radius: 3px;
  cursor: pointer;
}
.viewButton img {
  width: 16px;
  height: 16px;
  -webkit-filter: grayscale(1);
          filter: grayscale(1);
}
.viewButton:hover {
  border-color: #eb5e7c;
  border-color: var(--color-primary);
}
.viewButton:hover img {
  -webkit-filter: grayscale(0);
          filter: grayscale(0);
}


.listButton {
  -webkit-filter: grayscale(1);
          filter: grayscale(1);
  opacity: .5;
  cursor: pointer;
}
.listButton img {
  width: 20px;
  height: 20px;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none;
      user-select: none;
}
.listButton:hover {
  -webkit-filter: grayscale(0);
          filter: grayscale(0);
  opacity: 1;
}


.windowImage {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
}
.windowImage img {
  max-width: 100%;
  max-height: 300px;
}



.timelineEvent {
  margin-left: 30px;
  margin-bottom: 30px;
}
.timelineBoxContainer {
  padding: 15px 0;
  border-left: 5px solid #eb5e7c;
  border-left: 5px solid var(--color-primary);
}
.timelineDateContainer {
  position: relative;
  padding: 15px 0;
}
.timelineDateContainer:after {
  position: absolute;
  z-index: 1;
  top: 30px;
  right: -16px;
  display: block;
  content: '';
  width: 28px;
  height: 28px;
  background-color: #fff;
  background-color: var(--color-white);
  border-radius: 100%;
  border: 2px solid #eb5e7c;
  border: 2px solid var(--color-primary);
}
.timelineBoxContainer .box {
  margin-bottom: 10px;
}
.timelineBoxContainer .col-lg-6 {
  padding-left: 5px !important;
  padding-right: 5px !important;
}
.timelineDate {
  display: -webkit-flex;
  display: flex;
  color: #1F2227;
  color: var(--color-darkgrey);
  font-size: 20px;
  font-weight: 700;
  padding-top: 18px;
  padding-right: 20px;
  padding-left: 20px;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
}
.timelineDate.today {
  color: #eb5e7c;
  color: var(--color-primary);
  font-size: 24px;
}
.timelineEventType {
  font-weight: 700;
  margin-bottom: 6px;
}
.notifyInfo {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-align-items: center;
          align-items: center;
  font-weight: 700;
  margin-top: 8px;
}
.notifyInfo img {
  width: 18px;
  height: 18px;
  margin-right: 5px;
}
.confirmation {
  padding: 5px 15px 5px 5px;
  color: #fff;
  border-radius: 20px;
  display: -webkit-inline-flex;
  display: inline-flex;
}
.confirmation.pending {  
  background-color: #1f97dd;
}
.confirmation.confirmed {  
  background-color: #36bc8c;
}

@media (max-width: 991px){
  
  .timelineDate {
    -webkit-justify-content: flex-start;
            justify-content: flex-start;
  }
  .timelineDateContainer {
    border-left: 5px solid #eb5e7c;
    border-left: 5px solid var(--color-primary);
  }
  .timelineDateContainer:after {
    left: -16px;
  }

}



.historyTable {
  margin-bottom: 0;
}
.historyTable th {
  border-top: none;
}



.mailVariables input {
  width: 100%;
  border: none;
  background-color: #eceef1;
  margin-bottom: 3px;
  padding: 5px 8px;
  border-radius: 4px;
  font-size: 14px;
}





/* Bootstrap Override */

.row {  
  margin-right: -10px !important;
  margin-left: -10px !important;
}
.col, .col-1, .col-10, .col-11, .col-12, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-auto, .col-lg, .col-lg-1, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-auto, .col-md, .col-md-1, .col-md-10, .col-md-11, .col-md-12, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-auto, .col-sm, .col-sm-1, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-auto, .col-xl, .col-xl-1, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-auto { 
  padding-right: 10px !important;
  padding-left: 10px !important;
}
.btn {
  color: #fff !important;
  color: var(--color-white) !important;
  background-color: #70747B !important;
  background-color: var(--color-mediumgrey) !important;
  border-color: #70747B !important;
  border-color: var(--color-mediumgrey) !important;
  font-size: 12px !important;
  text-transform: uppercase !important;
  padding: 0.55rem 1.2rem !important;
  font-weight: 700 !important;
}
.btn-primary {
  color: #1F2227 !important;
  color: var(--color-darkgrey) !important;
  background-color: #fff !important;
  background-color: var(--color-white) !important;
  border-color: #fff !important;
  border-color: var(--color-white) !important;
  font-size: 13px !important;
}
.btn-primary:focus {  
  box-shadow: 0 0 0 0.2rem #eb5e7c25 !important;  
  box-shadow: 0 0 0 0.2rem var(--color-primary-trans) !important;
}
.btn-success {
  color: #fff !important;
  color: var(--color-white) !important;
  background-color: #36BC8C !important;
  background-color: var(--color-green) !important;
  border-color: #36BC8C !important;
  border-color: var(--color-green) !important;
}
.btn-success img {
  width: 18px !important;
  height: 18px !important;
}
.btn-lg {
  padding: .8rem 1.6rem !important;
}
.btn.iconButton {
  padding: .65rem !important;
  line-height: 13px !important;
  display: -webkit-inline-flex;
  display: inline-flex;
}
.btn.iconButton span {
  margin: 0 6px 0 12px;
}
.btn img {
  width: 14px;
  height: 14px;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none;
      user-select: none;
}
.btn.activeIconButton {
  padding: .65rem !important;
  background-color: #eb5e7c !important;
  background-color: var(--color-primary) !important;
  border-color: #eb5e7c !important;
  border-color: var(--color-primary) !important;
  display: -webkit-inline-flex;
  display: inline-flex;
  color: #fff !important;
  color: var(--color-white) !important;
}
.btn.activeIconButton span {
  margin: 0 6px 0 12px;
}
.btn-lg.iconButton {
  padding: .8rem .9rem !important;
  display: -webkit-inline-flex;
  display: inline-flex;
}
.btn-lg img {
  width: 19px;
  height: 19px;
}
.btn:disabled {
  background-color: #70747B !important;
  background-color: var(--color-mediumgrey) !important;
  border-color: #70747B !important;
  border-color: var(--color-mediumgrey) !important;
}
.btn-group > a:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.btn-group > a:not(:last-child) > .btn:not(.dropdown-toggle) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.btn-group a:first-child .btn {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}
.btn-group a:last-child .btn {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}
.btn-group .active .btn {
  background-color: #eb5e7c !important;
  background-color: var(--color-primary) !important;
  color: #fff !important;
  color: var(--color-white) !important;
}
.btn-group-toggle .btn:first-child.active {
  background-color: #36BC8C !important;
  background-color: var(--color-green) !important;
  border-color: #36BC8C !important;
  border-color: var(--color-green) !important;
}
.btn-group-toggle .btn:last-child.active {
  background-color: #ff5454 !important;
  background-color: var(--color-red) !important;
  border-color: #ff5454 !important;
  border-color: var(--color-red) !important;
}
.form-control-lg {
  height: calc(1.5em + 1.34rem + 2px) !important;
  font-size: 16px !important;
}
.form-label {
  font-weight: 700 !important;
  color: #70747B !important;
  color: var(--color-mediumgrey) !important;
}
.table {
  -webkit-columns: #1F2227;
          columns: #1F2227;
  -webkit-columns: var(--color-darkgrey);
          columns: var(--color-darkgrey);
}
.input-group-text {
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.input-group-text img {
  -webkit-filter: grayscale(1);
          filter: grayscale(1);
  width: 20px;
  height: 20px;
}

.custom-control-label::before {
  box-shadow: none !important;
}
.custom-control-input:checked~.custom-control-label::before {
  border-color: #36BC8C;
  border-color: var(--color-green);
  background-color: #36BC8C;
  background-color: var(--color-green);
}

.loginButton {
  background-color: #eb5e7c !important;
  background-color: var(--color-primary) !important;
  border-color: #eb5e7c !important;
  border-color: var(--color-primary) !important;
  color: #fff !important;
  color: var(--color-white) !important;
  width: 100%;
  height: 48px;
}

.input-group > .form-control {
  border-right: 0;
}
.input-group > .btn-outline-secondary {
  border-radius: 0 .25rem .25rem 0;
  background-color: transparent !important;
  color: #70747b !important;
  box-shadow: none !important;
}


/* CK Editor Override */
.ck-content a {
  color: #1f97dd;
  color: var(--color-blue);
  text-decoration: underline !important;
}
.ck-content a:hover {
  text-decoration: none !important;
}
